<div class="container">
    <section class="breadcrumb-page">
        <div class="sub-header">
            <h3>{{ 'Privacy Policy and Terms & Conditions' | translate }}</h3>
            <ol class="breadcrumb">
                <li>
                    <a href="javascript:;" [routerLink]="['/']"> {{ 'Home' | translate }} / </a>
                </li>
                <li>
                    <a href="javascript:;">&nbsp; {{ 'Termsandconditions' | translate }}</a>
                </li>
            </ol>
        </div>
    </section>
</div>

<div class="card-body pod-body mb-5">
    <div class="row p-3" >
        <div class="col-12">
            <h3 style="color: #542779; " class="mb-4 terms">
                {{ 'Termsandconditions' | translate }}
            </h3>
            <p class="text-center-align" style="color: #92278f; font-size: 20px;" [innerHTML]="termsContent"></p>
        </div>
        <div class="col-12">
            <h3 style="color: #542779;" class="mb-4 terms">
                {{ 'Privacy Policy' | translate }}
            </h3>
            <p class="text-center-align " style="color: #92278f; font-size: 20px;" [innerHTML]="privacyContent"></p>
        </div>
    </div>
    

      
   
</div>
