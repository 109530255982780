import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss']
})
export class StickyButtonComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  isAtBottom: boolean = false;

  isHovered: boolean = false; // Track hover state

  onButtonClick() {
    // Your button click logic here
  }

  // Methods to handle mouse events
  onMouseEnter() {
    this.isHovered = true; // Set hover state to true
  }

  onMouseLeave() {
    this.isHovered = false; // Set hover state to false
  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const scrollPosition = window.innerHeight + window.scrollY;
    const threshold = document.documentElement.scrollHeight - 10; // Adjust threshold as needed

    this.isAtBottom = scrollPosition >= threshold;
  }
}