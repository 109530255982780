<!-- <section class="breadcrumb-page breadcrumb-page-pb-120">
    <div class="sub-header">
        <h3>{{ 'Account' | translate }}</h3>
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;">&nbsp; {{ 'Change Password' | translate }} </a>
            </li>
        </ol>
    </div>
</section> -->

<!-- <ng-container *ngIf="user">

    <div class="container">
        <div class="custom-section-3">
            <div class="row" style="max-width:100%">
                <div class="col-md-3 col-xl-3 card h-f">
                    <h5 class="heading-title">{{ 'Settings' | translate }}</h5>
                    <hr class="p-underline">
                    <ul class="list-unstyled account-list">
                        <li><a [routerLink]="['/', locale.prefix, 'profile']" class="py-1 d-block">{{ 'Update Account' | translate }}</a></li>
                        <li><a [routerLink]="['/', locale.prefix, 'profile', 'security']" class="py-1 d-block">{{ 'Change Password' | translate }}</a></li>
                    </ul>
                </div>
                <div class="col-md-8 col-xl-8 ml-3 card">
                    <h5 class="heading-title">{{ 'Change Password' | translate }}</h5>
                    <hr class="p-underline">
                    <div class="login-form">
                        <form [formGroup]="form">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="old_password">
                                        <i class="far fa-key"></i>
                                    </span>
                                </div>
                                <input name="old_password" type="password" [formControlName]="'old_password'" class="form-control form-control-2" placeholder="{{ 'Current Password' | translate }}" aria-label="Current Password" aria-describedby="Current Password">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="old_password">
                                        <i class="far fa-key"></i>
                                    </span>
                                </div>
                                <input name="new_password" type="password" [formControlName]="'new_password'" class="form-control form-control-2" placeholder="{{ 'New Password' | translate }}" aria-label="New Password" aria-describedby="New Password">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="old_password">
                                        <i class="far fa-key"></i>
                                    </span>
                                </div>
                                <input name="new_password_confirmation" type="password" [formControlName]="'new_password_confirmation'" class="form-control form-control-2" placeholder="{{ 'New Password Confirmation' | translate }}" aria-label="New Password Confirmation" aria-describedby="New Password Confirmation">
                            </div>
                            <button 
                                [disabled]="form.disabled"
                                (click)="updateProfile()"
                                type="button" 
                                class="btn btn-primary btn-lg btn-block submit-btn">
                                {{ 'Submit' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container> -->
<div class="container">

    <section class="breadcrumb-page">
      <div class="sub-header">
        <h3>{{ 'Account' | translate }}</h3>
        <ol class="breadcrumb">
          <li>
            <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
          </li>
          <li>
            <a href="javascript:;">&nbsp; {{ 'Settings' | translate }} </a>
        </li>
        </ol>
      </div>
    </section>
    
<ng-container *ngIf="user">

    <div class="row mt-5 ">
        
     
        <!-- <div class="col-md-12 col-12 color-logo">
            <a [routerLink]="['/', locale.prefix]">
                <img src="assets/podcasti/images/color-logo.png" alt="">
            </a>
        </div> -->
        <div class="col-md-9 col-12 card" style="margin: auto;" >
            <ul class="list-unstyled account-list">
                <li>
                    <a [routerLink]="['/', locale.prefix, 'profile']" 
                       [routerLinkActiveOptions]="{ exact: true }" 
                       routerLinkActive="active-link" 
                       class="py-1 d-block">{{ 'Update Account' | translate }}</a>
                </li>
                <li>
                    <a [routerLink]="['/', locale.prefix, 'profile', 'security']" 
                       routerLinkActive="active-link" 
                       class="py-1 d-block">{{ 'Change Password' | translate }}</a>
                </li>
            </ul>
          
            <div class="login-form" style="margin-bottom: 30px;padding: 60px 30px 100px;">
                <h5 class="main-title-4 ">
                    {{ 'Settings' | translate }}
                    <!-- <span>{{ 'new account' | translate }}</span> -->
                </h5>
                <form [formGroup]="form">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="old_password">
                                <i class="fa fa-key"></i>
                            </span>
                        </div>
                        <input name="old_password" type="password" appValidateInputDirective="old_password is required" [formControlName]="'old_password'" class="form-control form-control-2" placeholder="{{ 'Current Password' | translate }}" aria-label="Current Password" aria-describedby="Current Password">
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="old_password">
                                <i class="fa fa-key"></i>
                            </span>
                        </div>
                        <input name="new_password" type="password" appValidateInputDirective="new_password is required" [formControlName]="'new_password'" class="form-control form-control-2" placeholder="{{ 'New Password' | translate }}" aria-label="New Password" aria-describedby="New Password">
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="old_password">
                                <i class="famain-title-4 fa-key"></i>
                            </span>
                        </div>
                        <input name="new_password_confirmation" type="password"  appValidateInputDirective="new_password_confirmation is required" [formControlName]="'new_password_confirmation'" class="form-control form-control-2" placeholder="{{ 'New Password Confirmation' | translate }}" aria-label="New Password Confirmation" aria-describedby="New Password Confirmation">
                    </div>
                    <button 
                    [disabled]="form.invalid"
                        (click)="updateProfile()"
                        type="button" 
                        class="btn btn-primary btn-lg btn-block submit-btn">
                        {{ 'Submit' | translate }}
                    </button>
                </form>
           
            </div>
        </div>
        <!-- <div class="col-md-6 col-12 p-0">
            <div class="login-image">
                <img src="assets/podcasti/images/background.png" alt="">
             
            </div>
        </div> -->
    </div>
</ng-container>
</div>










