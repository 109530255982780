import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ErrorProcessingService } from 'src/app/services/error-processing.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Category, Locale, Program, Response } from 'src/app/types';
declare var $: any;

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnDestroy
{
	categoryData: any;
	private _unsubscribeAll: Subject<any>;

	public locale          : Locale    | null = null;

	public category        : Category  | null  = null;
	public title     :string;

	public most_listened   : Program[] | null = null;

	public latest_programs : Program[] | null = null;

	public page            : number 		  = 1;

	public hasMorePages    : boolean 		  = false;

	constructor(
		private localeService   : LocaleService,
		private errorProcessing : ErrorProcessingService,
		private api             : ApiService,
		private activatedroute  : ActivatedRoute
	)
	{
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void
	{
		this.localeService.locale$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((locale: Locale) => {
			this.activatedroute.paramMap
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((params) => {
				this.locale = locale;
				this.page   = 1;
				this.loadData(locale, params.get('id'));
				this.catrgorbyid(locale, params.get('id'))
			});
		});

	}
	catrgorbyid(locale: Locale, category_id: any | null) {

		const baseImageUrl = 'https://backend.podqasti.com/img/400x400'; // Base image URL
		const defaultCategoryId = 184; // Example default category ID if needed
	  
		this.api.getCategoryById(locale, category_id || defaultCategoryId).subscribe(
		  (data) => {
			// Assuming the API returns an array or list of category data
			this.categoryData = data.result.list.map((item: any) => {
			  // Append base image URL to the image path
			  return {
				...item, // Spread the existing item data
				image: `${baseImageUrl}/${item.image}` // Combine base URL with image path from API
			  };
			});
	  
			console.log('Category data with images:', this.categoryData);
		  },
		  (error) => {
			console.error('Error fetching category data:', error);
		  }
		);
	  }
	  
    ngOnDestroy() : void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	loadData(locale: Locale, category_id: string | null)
	{
		this.api.category(locale.prefix, {
			category_id : category_id,
			page		: this.page,
		})
		.pipe(take(1))
		.subscribe((data: Response) => {
			console.log("data",data)
			let _data = this.errorProcessing.auto(data);
			if (_data) {
			
this.title=data.result.category.title;
console.log("this.most_listened",this.title)
				this.category        = _data.category.programs.list;
				this.hasMorePages    = _data.category.programs.has_more_pages;
				this.most_listened   = _data.most_listened   || null;
				this.latest_programs = _data.latest_programs || null;
			}
			console.log("this.most_listened",this.most_listened)
			console.log("this.most_listened",this.category)

			$.getScript('assets/podcasti/js/custom.js');
		});
	}
}
