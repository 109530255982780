<div class="container">
  <!-- Breadcrumb Navigation -->
  <section class="breadcrumb-page">
    <div class="sub-header">
      <h3>{{ 'Search in' | translate }}</h3>
      <ol class="breadcrumb">
        <li>
          <a href="javascript:;" [routerLink]="['/']">{{ 'Home' | translate }} / </a>
        </li>
        <li>
          <a href="javascript:;">&nbsp; {{ 'Episodes' | translate }}</a>
        </li>
      </ol>
    </div>
  </section>

  

  <!-- Tab Content -->
  <section class="tab-content">
    <!-- Tab Navigation -->
  <ul class="nav justify-content-center" role="tablist">
    <li class="nav-item col-6 col-md-4">
      <div
        class="tab"
        [class.active]="selectedTab === 'programs'"
        (click)="selectTab('programs')">
        {{ 'Programs' | translate }}
      </div>
    </li>
    <li class="nav-item col-6 col-md-4">
      <div
        class="tab"
        [class.active]="selectedTab === 'episodes'"
        (click)="selectTab('episodes')">
        {{ 'Episodes' | translate }}
      </div>
    </li>
    <li class="nav-item col-6 col-md-4">
      <div
        class="tab"
        [class.active]="selectedTab === 'broadcasters'"
        (click)="selectTab('broadcasters')">
        {{ 'Podcasters' | translate }}
      </div>
    </li>
  </ul>
    <ng-container *ngIf="selectedTab === 'programs'">
      <ng-container  *ngIf="categories && categories.length > 0; else noResults" >
        <div class="row" style="margin:auto;">
          <div class="col-12 col-md-4" *ngFor="let program of categories">
            <app-program-podcaster-card [program]="program" [locale]="locale" [footer]="false"></app-program-podcaster-card>
          </div>
        </div>
      </ng-container >
    </ng-container>

    <ng-container *ngIf="selectedTab === 'episodes'">
      <ng-container *ngIf="episodes && episodes.length > 0; else noResults">
       



        <div class="row"  style="margin:auto;">
          <div class="col-12 col-md-4" *ngFor="let item of episodes">
              <app-vertical-episode [item]="item" [locale]="locale" [footer]="false" [appeartext]="false">
              </app-vertical-episode>
          </div>
      </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'broadcasters'">
      <ng-container  *ngIf="broadcasters && broadcasters.length > 0; else noResults">
        <div class="row"  style="margin:auto;">
          <div class="col-12 col-md-4" *ngFor="let item of broadcasters">
            <app-podcaster-card [broadcaster]="item" [locale]="locale" [footer]="false"></app-podcaster-card>
          </div>
        </div>
      </ng-container >
    </ng-container>

    <!-- No Results Found Template -->
    <ng-template #noResults>
      <div class="no-results">
        <h3> {{ 'Sorry, we couldn’t find any results for your search. Feel free to explore other topics.' | translate }}</h3>
      </div>
    </ng-template>
  </section>
</div>
